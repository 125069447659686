.choiceAnswer {
  display: flex;
  position: relative;
}

.dropDown {
  max-width: 10em;
  margin-left: 1rem;
}

.input {
  flex-grow: 1;
  max-width: none;
  padding: 0.36em 0.75em;
}

.handle,
.deleteButtonWrapper {
  padding-top: 0.36em;
  padding-bottom: 0.36em;
}

.handle {
  padding-top: 0.43em;
}

.noHandle,
.noDeleteButton {
  visibility: hidden;
}

.deleteButtonWrapper {
  opacity: 0;
  transition: opacity 0.2s ease;
}

.handle {
  width: 1rem;
  margin-left: -0.5rem;
  padding-left: 0.5rem;
  display: block;
  color: transparent;
  flex-shrink: 0;
  transition: color 0.2s ease;
}

.choiceAnswer:hover .deleteButtonWrapper {
  opacity: 1;
}

.choiceAnswer:hover .handle {
  color: var(--color-blue-400);
}

.deleteButtonWrapper {
  position: absolute;
  right: -1.75rem;
  padding-left: 0.25rem;
}

.deleteButton {
  color: var(--color-blue-400);
  border: 0;
  margin: 0;
  background: none;
  height: 1.5rem;
  width: 1.5rem;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: white;
  box-shadow: var(--shadow-button);
  border-radius: 100em;
}

.iconSingle,
.iconMultiple {
  border: 2px solid var(--color-blue-300);
  height: 1em;
  width: 1em;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 0.25em;
  margin-right: 0.75em;
  align-self: center;
}

.iconSingle {
  border-radius: 100em;
}
