:root {
  --sub-navigation-width: 14rem;
  --main-navigation-height: 3.5rem;

  --padding-input-vertical: 0.6em;
  --padding-input-horizontal: 0.75em;
  --padding-input: var(--padding-input-vertical) var(--padding-input-horizontal);
  --padding-button: 0.6em 1.25em;
  --padding-button-big: 1em 1.25em;

  /* Default focus outline */
  --focus-outline: 2px solid var(--color-background-cyan-bravo);
  --focus-outline-offset: 2px;

  /* New design 2021 */
  --color-blue-050: #f2f2fa;
  --color-blue-100: #ebebf4;
  --color-blue-200: #d9d9e5;
  --color-blue-300: #bbbbca;
  --color-blue-400: #9393a5;
  --color-blue-500: #67677d;
  --color-blue-600: #42425a;
  --color-blue-700: #272742;
  --color-blue-800: #161634;
  --color-blue-900: #0a0a2d;

  --color-teal-050: #e0fff5;
  --color-teal-400: #15e0af;
  --color-teal-500: #00a786;
  --color-teal-600: #007461;
  --color-teal-700: #004d43;
  --color-teal-800: #00312c;
  --color-teal-900: #001f1c;

  --color-orange-050: #fff4f0;
  --color-orange-200: #ffa384;

  --color-primary-1: var(--color-teal-400);
  --color-primary-1-hover: #32f8cc;
  --color-primary-2: var(--color-blue-900);
  --color-primary-2-hover: var(--color-blue-700);

  --color-secondary-1: #ff4018;
  --color-secondary-2: var(--color-orange-050);
  --color-secondary-2-darker: #f4eae6;

  --color-border-on-light: #e9e9ec;

  --box-shadow-slight:
    0 3px 2px -2px rgba(0, 0, 0, 0.12), 0 4px 6px rgba(0, 0, 0, 0.04);
  --box-shadow-card:
    0px 2px 4px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.05);
  --box-shadow-wider:
    0 6px 4px -4px rgba(0, 0, 0, 0.04), 0 6px 28px rgba(0, 0, 0, 0.06);

  /* overwrite variables here */
  --font-family-base: "AvenirNextLTPro", Helvetica, sans-serif;
  --font-family-serif: "Tiempos Text", serif;

  /* Colors: Gray */
  --color-gray-50: #f2f2fa;
  --color-gray-100: #ebebf4;
  --color-gray-200: #d9d9e5;
  --color-gray-300: #bbbbca;
  --color-gray-400: #9393a5;
  --color-gray-500: #67677d;
  --color-gray-600: #42425a;
  --color-gray-700: #272742;
  --color-gray-800: #161634;
  --color-gray-900: #0a0a2d;

  --color-red-50: #ffebee;
  --color-red-100: #ffcdd2;
  --color-red-200: #ef9a9a;
  --color-red-300: #e57373;
  --color-red-400: #ef5350;
  --color-red-500: #f44336;
  --color-red-600: #e53935;
  --color-red-700: #d32f2f;
  --color-red-800: #c62828;
  --color-red-900: #b71c1c;

  /* https://material.io/design/color/the-color-system.html#tools-for-picking-colors -> enter #00a748 as primary color in tool */

  /* Color: Others */
  --color-deep-orange-200: #ffab91; /* Status label border */
  --color-deep-orange-300: #ff8a65; /* Status label text */
  --color-deep-purple-200: #b39ddb; /* Status label border */
  --color-deep-purple-300: #9575cd; /* Status label text */
  --color-green-600: #00a748; /* Status label text */

  --color-logo-cyan: var(--color-primary-1);
  --color-logo-blue: #223f82;
  --color-logo-text: #333132;
  --color-logo-cyan-light: #dff2f1;
  --color-logo-cyan-light2: #c7eae8; /* On gray background */

  /* Button */
  --color-logo-cyan-hover: #009e95;

  /* Text colors */

  --color-text-body: var(--color-primary-2);
  --color-text-on-background: #263234;
  --color-text-gray-on-background: #646b6b;
  --color-text-on-background-gray: #666;
  --color-text-on-white: #1f1f1f;
  --color-text-gray: var(--color-gray-600);
  --color-text-gray-on-white: var(--color-text-gray);
  --color-text-light-gray-on-white: var(--color-gray-500); /*#999;*/
  --color-text-placeholder: var(--color-blue-500);
  --color-text-soft-on-background-highligt1: #768281;
  --color-text-on-background-highligt1: #2c3a38;
  --color-text-dark-gray: var(--color-blue-600);

  /* Colors */
  --color-background-gray: #eee;
  --color-background-light-gray: #fafafa;
  --color-background: var(--color-secondary-2);
  --color-background-cyan-bravo: var(--color-primary-1);
  --color-background-highligt1: #d2f9f6;
  --color-text-error: var(--color-secondary-1);
  --color-border-error: var(--color-secondary-1);
  --color-text-success: #149e46;
  --color-border-success: #9ddab3;
  --color-border-info: #ffc659;
  --color-text-info: #e68300;
  --color-link: #0074c2;

  /* Colors: Borders */
  --color-border-gray: var(--color-blue-200);
  --color-border-transparent: rgba(10, 10, 45, 0.09);

  /* Colors: Buttons */
  --color-button-gray: white;
  --color-button-gray-hover: var(--color-blue-100);
  --color-button-primary: var(--color-primary-2);
  --color-button-primary-hover: var(--color-primary-2-hover);

  /* Todo: rename --color-button-primary etc. to --background-color-button-primary */
  --background-color-button-warning: var(--color-red-50);
  --background-color-button-warning-hover: var(--color-red-100);
  --color-button-warning: var(--color-red-900);

  --font-size-small: 0.875rem;
  --font-size-uppercase: 0.875rem;
  --line-height-button: 1.3143;

  --shadow-card: 0 2px 4px rgba(0, 0, 0, 0.1), 0 0px 2px rgba(0, 0, 0, 0.05);

  --shadow-card-elevated: var(--shadow-card);
  /*--shadow-button: 0 0 2px 0 rgba(0, 0, 0, 0.10), 0 2px 4px 0 rgba(0, 0, 0, 0.10);*/
  --shadow-button: var(--box-shadow-slight);
  /*--shadow-button-hover: 0 0 4px 0 rgba(0, 0, 0, 0.12), 0 4px 8px 0 rgba(0, 0, 0, 0.12);*/
  --shadow-button-hover: var(--box-shadow-slight);
  --shadow-button-warning:
    0 0 2px 0 rgba(183, 28, 28, 0.1), 0 2px 4px 0 rgba(183, 28, 28, 0.1);
  --shadow-button-warning-hover:
    0 0 4px 0 rgba(183, 28, 28, 0.12), 0 4px 8px 0 rgba(183, 28, 28, 0.12);
  --border-radius-1: 0.25rem;
  --border-radius-button: 0.42rem;
  --border-radius-card: 0.5625rem;
  --border-radius-2: 0.6667rem;

  --shadow-dropdown: /* 0 0 0 2px var(--color-secondary-2-darker),*/
    var(--shadow-card), var(--box-shadow-wider);

  /* Transitions */
  --transition-button:
    box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    border-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  --transition-button-hover: none;

  --padding-card: 1.5rem;
}

:root {
  /* Todo: this could be provided only inside the design system container to limit its scope */
  --inline-icon-size: 1rem;
  --inline-icon-width: var(--inline-icon-size);
  --inline-icon-height: var(--inline-icon-size);
}

:root {
  --overflow-menu-offset-top: 3rem;
}

:root {
  --spacing-stack-default: 1.5rem;
}
