.videoRating {
  display: inline-block;
  margin: 0;
}

.star {
  color: goldenrod;
  display: inline-block;
  padding: 0 0.05em;
  cursor: pointer;
}

.star:first-child {
  padding-left: 0;
}

.star:last-child {
  padding-right: 0;
}

.videoRating_isReadOnly .star {
  cursor: default;
}

.label {
  opacity: 0.5;
}
