.overflowMenu {
  position: relative;
  --overflow-menu-trigger-button-background: var(--color-blue-100);
  --overflow-menu-trigger-button-color: var(--color-text-body);
}

.button {
  padding: 0;
  font-size: 0.875rem;
  height: 2.5em;
  width: 2.5em;
  line-height: 1.8;
  border-radius: 2em;
  cursor: pointer;
  border: 2px solid transparent;
  background: var(--overflow-menu-trigger-button-background);
  color: var(--overflow-menu-trigger-button-color);
  transition: var(--transition-button);
}

.button:hover {
  background: color-mix(
    in srgb,
    var(--overflow-menu-trigger-button-background) 92%,
    var(--overflow-menu-trigger-button-color) 8%
  );
  transition: var(--transition-button-hover);
}

.button:focus {
  outline: 0;
}

.button:focus-visible {
  outline: var(--focus-outline);
  outline-offset: var(--focus-outline-offset);
}

.menu {
  color: var(--color-text-body);
  background: white;
  box-shadow: var(--shadow-dropdown);
  padding: 0.5rem;
  border-radius: var(--border-radius-button);
  display: none;
  position: absolute;
  top: var(--overflow-menu-offset-top);
  right: 0;
}

.menu--active {
  display: block;
  z-index: 999;
}
