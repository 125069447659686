.filter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
}

.previewButton {
  color: var(--color-blue-100);
  background: var(--color-blue-700);
}

.previewButton:hover {
  background: var(--color-blue-800);
}

.glint {
  animation: flash 1s linear;
}

* + .notes {
  margin-top: 1rem;
}

.listItem {
  scroll-margin-top: 0.5rem;
}

.editNote + .listItem,
.editNote {
  margin-top: 0.5rem;
}

.overflowMenu {
  --overflow-menu-trigger-button-background: var(--color-blue-600);
  --overflow-menu-trigger-button-color: var(--color-blue-300);
}

.aiInsightsHiddenInfo {
  font-size: var(--font-size-small);
  text-align: center;
  color: var(--color-blue-500);
}

.showHiddenAiInsights {
  color: var(--color-blue-400);
  cursor: pointer;
}

@keyframes flash {
  25% {
    background: var(--color-blue-600);
  }
  75% {
    background: var(--color-blue-600);
  }
}
