.root {
  width: 100%;
  display: block;
  font-size: 1em;
  margin: 0;
}

.root + .root {
  margin-top: 0.5em;
}

.root input {
  clip-path: polygon(0 0);
  position: absolute;
}

.root input + label {
  display: flex;
}

.root input + label:before {
  content: "";
  vertical-align: middle;
  display: inline-block;
  height: 0.75em;
  width: 0.75em;
  border-radius: 1em;
  background: white;
  margin: 0.4em 0.9em 0.5em 0.4em;
  box-shadow:
    0 0 0 0.3em white,
    0 0 0 0.4em var(--color-blue-300);
  flex-shrink: 0;
}

.root input:checked + label:before {
  background: var(--color-logo-cyan);
}

.root input:focus + label:before {
  box-shadow:
    0 0 0 0.3em white,
    0 0 0 0.4em var(--color-blue-400);
}

.disabled input + label:before {
  box-shadow:
    0 0 0 0.3em white,
    0 0 0 0.4em var(--color-blue-200);
}

.disabled input:checked + label:before {
  background: var(--color-gray-300);
}

.disabled label {
  color: var(--color-gray-400);
}

.fullWidth {
  max-width: none;
}
