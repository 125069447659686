.videoStar {
  all: unset;
  opacity: 0.8;
  cursor: pointer;
}

.videoStar_isReadOnly {
  cursor: default;
}

.videoStar:not(.videoStar_isReadOnly):hover {
  opacity: 1;
}

.videoStar_isGotStarred {
  animation: pop 0.3s ease;
}

@keyframes pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
