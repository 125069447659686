.content {
  composes: content from "../../helpers.css";
}

.formContent {
  composes: formContent from "../../helpers.css";
}

.cardHeading1 {
  margin: 0;
}

.headingInvites {
  margin: 0;
}

.accountOwnerTag {
  text-align: right;
  white-space: nowrap;
}

.accountOwnerLabel {
  font-style: italic;
  padding: 0.406rem 0;
  color: var(--color-blue-400);
  font-size: 0.875rem;
  display: inline-block;
  white-space: nowrap;
}

.accountOwnerCanList {
  margin-bottom: 0;
}

.dropDownRole {
  width: 8.2em;
}

.dropDownRoleContent {
  width: 100vw;
  max-width: 16rem;
  padding: 0.5rem;
}

.dropDownItemsDivider {
  border-color: var(--color-border-on-light);
  border-width: 1px;
  margin: 0.5rem 0;
}

div.radioGroupItemRole + div.radioGroupItemRole {
  margin-top: 0;
}

.radioGroupItemRole {
  box-sizing: border-box;
  padding: 0.5rem;
}

.radioGroupItemRole,
.radioGroupItemRole * {
  cursor: pointer;
}

.radioGroupItemRole:hover {
  background: var(--color-blue-050);
  border-radius: var(--border-radius-1);
}

.roleDescription {
  margin-top: 0.1rem;
  color: var(--color-blue-600);
  font-size: var(--font-size-small);
}

.teamMembers {
  margin-top: 1.75rem;
  width: min-content;
  max-width: 100%;
}

.teamMember {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  align-items: baseline;
}

.teamMember:first-child {
  margin-top: 0;
}

.teamMemberEmail {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 1;
  margin-right: 1rem;
}

.teamMemberRemove {
  white-space: nowrap;
  text-align: left;
  background: none;
  border: 0;
  color: inherit;
  width: 100%;
  display: block;
  border-radius: var(--border-radius-1);
  cursor: pointer;
  padding: 0.5rem 0.88rem 0.5rem 0.5rem;
  line-height: 1.2;
}

.teamMemberRemove:hover {
  background: var(--color-blue-100);
}

.teamMemberRemoveIcon {
  margin-right: 0.5rem;
  margin-left: 0.2rem;
  color: var(--color-gray-600);
  font-size: var(--font-size-small);
}

.addTeamMember {
  margin-top: 2rem;
}

.invitations {
  margin-top: 1.5rem;
}

.invitation {
  margin-top: 1.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.invitation:first-child {
  margin-top: 0;
}

.invitationRole {
  margin-left: 0.5rem;
  color: var(--color-blue-400);
  font-size: var(--font-size-small);
}

.invitationActions {
  margin-top: 0.25rem;
  font-size: var(--font-size-small);
}

.invitationActions > * {
  margin-right: 1rem;
  color: var(--color-blue-400);
}

.invitationsEmptyState {
  margin-top: 1rem;
  color: var(--color-blue-400);
}

.usage {
  color: var(--color-blue-400);
  font-size: var(--font-size-small);
}

.overLimit {
  font-style: italic;
  color: var(--color-blue-500);
}
